import { HTTP } from '../../axios/axios'

async function getAll (lang) {
  try {
    const res = await HTTP.get('/titles', { headers: { 'accept-language': lang } })
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (title, lang) {
  title.lang = lang
  return await HTTP.post('/titles', title)
}
async function update (title, id) {
  return await HTTP.patch('/titles/' + id, title)
}
async function deleteOne (title) {
  return await HTTP.delete('/titles/' + title._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/titles/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
